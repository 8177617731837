<template>
  <div>
    <search-system-package @submit="submitSearch" />
    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <template slot="name" slot-scope="name,record">
        <span v-if="record.is_self_choose === true">{{ name }}</span>
        <a v-else @click="showDeatilModal(record)">{{ name }}</a>
      </template>
      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs && imgs.length > 0" class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <div slot="effective" slot-scope="effective">
        <span>{{ effective ? '有效': '失效' }}</span>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
    <!-- 套餐模态框 -->
    <show-package
      v-if="isShowModal"
      :visible.sync="isShowModal"
      :record="detailRecord"
    />
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchSystemPackage from '@/views/tenants/package_managements/Search'
import PreviewImage from '@/components/PreviewImage'
import { findPackages } from '@/api/package_management'
import { formatCurrency } from '@/utils/filter'

export default {
  name: 'SystemPackageList',
  components: {
    Pagination,
    PreviewImage,
    SearchSystemPackage,
    ShowPackage: () => import('@/views/tenants/package_managements/Show')
  },
  data() {
    return {
      query: {},
      detailRecord: {},
      data: [],
      loading: true,
      previewVisible: false,
      isShowModal: false,
      previewImages: [],
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  computed: {
    tenantId() {
      return parseInt(this.$route.params.id)
    },
    columns() {
      return [
        {
          title: '套餐名称',
          dataIndex: 'name',
          width: 120,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '项目数量',
          width: 90,
          dataIndex: 'itemCount'
        },
        {
          title: '价格(元)',
          width: 120,
          dataIndex: 'price',
          customRender: formatCurrency
        },
        {
          title: '图片',
          width: 100,
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '套餐介绍',
          width: 150,
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          title: '状态',
          width: 65,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          width: 180,
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    showDeatilModal(record) {
      this.detailRecord = record
      this.isShowModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },
    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },
    fetchData() {
      this.loading = true
      findPackages(Object.assign({ tenant_id: this.tenantId }, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
